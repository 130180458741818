"use strict";
/*----------------------------------------------------------------------------
 Login Page
----------------------------------------------------------------------------*/
/* JS Dependencies
--------------------------------------*/
import ApiToken from '../../module/ApiToken';

/* CSS Dependencies
--------------------------------------*/
import '../../../css/pages/pageLogin.scss';

const token = new ApiToken();

token.destroyToken();
