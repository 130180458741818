"use strict";
/*----------------------------------------------------------------------------
 Class for taking Api Token
----------------------------------------------------------------------------*/
/* Babel Polyfills
--------------------------------------*/
import "@babel/polyfill";

/* JS Dependencies
--------------------------------------*/
import Api from './Api';
import moment from 'moment';

/* Private Constants
--------------------------------------*/
const _TOKEN_GENERATOR_URL = '/tacho/token';
const _TOKEN_LIFE_TIME_IN_SECONDS = 86400;
const _TOKEN_LIFE_TIME_SAFE_BUFFER_SECONDS = 100;
const _TOKEN_STORAGE_PARAM_NAME = 'fahrschuleJWT';

/* Private Static Variables
--------------------------------------*/
let _tokenPromise = null;

/* Private Static Functions
--------------------------------------*/
function _getTokenFromCache() {
    return JSON.parse(localStorage.getItem(_TOKEN_STORAGE_PARAM_NAME));
}

function _setTokenToCache(token) {
    localStorage.setItem(_TOKEN_STORAGE_PARAM_NAME, JSON.stringify({
        token,
        createdAt: moment().unix(),
    }));
}

function _isValidToken(token) {
    if (token == null) {
        return false;
    }

    const tokenExpireDateUnix =
        token.createdAt + _TOKEN_LIFE_TIME_IN_SECONDS - _TOKEN_LIFE_TIME_SAFE_BUFFER_SECONDS;
    const nowUnix = moment().unix();

    return tokenExpireDateUnix >= nowUnix;
}

function _destroyToken() {
    localStorage.removeItem(_TOKEN_STORAGE_PARAM_NAME);
}

async function _createNewToken() {
    _destroyToken();

    const api = new Api(''); // Empty prefix for token generation route needed.
    api
        .setUrl(_TOKEN_GENERATOR_URL)
        .setData();

    try {
        if (_tokenPromise == null) {
            _tokenPromise = api.apiCall('post', true);
        }
    } catch (e) {
        throw e;
    }

    const tokenResponse = await (async () => _tokenPromise)();

    if (tokenResponse.token == null) {
        throw new Error('Unexpected error while obtaining token');
    }

    _setTokenToCache(tokenResponse.token);

    return _getTokenFromCache();
}

export default {
    getToken: async () => {
        let token = await _getTokenFromCache();

        if (_isValidToken(token)) {
            return token;
        }

        try {
            token = await _createNewToken()
        } catch (e) {
            throw e;
        }

        return token;
    },
    destroyToken: _destroyToken,
};
