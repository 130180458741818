"use strict";
/*----------------------------------------------------------------------------
 Api Class
----------------------------------------------------------------------------*/
/* Babel Polyfills
--------------------------------------*/
import "@babel/polyfill";

/* JS Dependencies
--------------------------------------*/
import apiToken from './ApiToken';
import { TACHO_VERSION } from '../version';

/* Constants
--------------------------------------*/
// Default Api prefix setting.
const DEFAULT_API_PREFIX = '/api/v1';

/**
 * Providing base operations for API.
 */
export default class Api {
    constructor(customApiPrefix = null) {
        this.customApiPrefix = customApiPrefix;

        this.beforeRequest = null;
        this.callback = null;
        this.event = {};
        this.settings = {};
        this.url = null;
        this.xhrPromise = null;
    }

    static get DEFAULT_API_PREFIX() {
        return DEFAULT_API_PREFIX;
    }

    setBeforeRequest(callback = null) {
        this.beforeRequest = callback;

        return this;
    }

    setCallback(callback = null) {
        this.callback = callback;

        return this;
    }

    setData(data = null) {
        if (data != null) {
            this.settings.data = data;
        } else {
            delete this.settings.data;
        }

        return this;
    }

    removeData() {
        delete this.settings.data;
    }

    setEvent(element, eventName) {
        if (element == null || eventName == null) {
            throw new Error('Please specify element for the event & it\'s name');
        }

        this.event.$element = $(element);
        this.event.name = eventName;

        return this;
    }

    setSettings(settings) {
        if (settings != null) {
            $.extend(this.settings, settings);
        }

        return this;
    }

    setUrl(url) {
        if (url != null) {
            this.url = url;
        }

        return this;
    }

    validateRequestData(method = null) {
        if (!this.url) {
            throw new Error('Please provide API url');
        }

        switch (method.toLowerCase()) {
            case 'delete':
            case 'get':
            case 'post':
            case 'put':
                break;

            default:
                throw new Error('API method not supported');
        }

        return true;
    }

    prepareApiCall() {
        if (!this.settings.success) {
            this.settings.success = (data, textStatus, jqXHR) => {
                if (this.callback != null) {
                    this.callback(data);
                }

                if (this.event.name != null && this.event.$element != null) {
                    this.event.$element.trigger(this.event.name)
                }
            };
        }
    }

    abort() {
        if (this.xhrPromise) {
            this.xhrPromise.abort();
            this.xhrPromise = null;
        }

        return this;
    }

    async apiCall(method = null, noToken = false) {
        this.validateRequestData(method);

        this.settings.type = this.settings.method = method.toUpperCase();

        if ('get' !== method) {
            this.settings.contentType = 'application/json';
        }
        this.prepareApiCall();

        let tokenObj = null;

        /** Call user func on beforeSend if needed, still need to run our code to send token anyway. */
        if (!noToken) {
            tokenObj = await apiToken.getToken();
        }

        let result;
        try {
            result = await this.doRequest(tokenObj);
        } catch (e) {
            throw e;
        }

        return result;
    }

    async doRequest(tokenObj = null) {
        this.settings.beforeSend = (jqXHR, settings) => {
            if (tokenObj) {
                jqXHR.setRequestHeader("Authorization", 'Bearer ' + tokenObj.token);
            }

            if (this.beforeRequest != null) {
                this.beforeRequest.call(this, jqXHR);
            }
            jqXHR.setRequestHeader("X-Fhr-Client", `tacho-calendar ${TACHO_VERSION}`);
        };

        const apiPrefix = (this.customApiPrefix == null) ? Api.DEFAULT_API_PREFIX : this.customApiPrefix;

        this.xhrPromise = $.ajax(
            `${apiPrefix}${this.url}`,
            this.settings
        );

        return this.xhrPromise;
    }
}
